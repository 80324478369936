import React from 'react';
import {graphql, withPrefix} from 'gatsby';
import PropTypes from 'prop-types';
import {Helmet} from 'react-helmet';
import {createGlobalStyle} from 'styled-components';
import Layout from '../components/Layout/index.js';
import SEO from '../components/seo';
import ParseHTML from '../components/ParseHTML';
import '../components/Page/homepage.css';
import useScript from '../hooks/use-script';
import useCurrentContent from '../hooks/use-current-content';
import {useI18next} from 'gatsby-plugin-react-i18next';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: Roboto,sans-serif;
    background: #ffffff;
    font-size: 100%;
    line-height: 1.6;
    text-size-adjust: 100%;
  }
  br {
    display: none;
  }

  @media all{
    html{
      font-family: Roboto,sans-serif;
      text-size-adjust:100%;
    }
  }
`;

const Homepage = ({
  data: {
    locales,
    page: {
      edges: [
        {
          node: {content, seo, translated},
        },
      ],
    },
  },
}) => {
  useScript(withPrefix('static/main-homepage.js?v=0.61'));
  const getContent = useCurrentContent([translated, content]);
  const {language} = useI18next();

  var canonical =
    language === 'en'
      ? 'https://www.dacast.com/'
      : `https://www.dacast.com/${language}/`;
  if (process.env.GATSBY_ENV != 'production') {
    canonical =
      language === 'en'
        ? 'https://dev.dacast.com/'
        : `https://dev.dacast.com/${language}/`;
  }

  var gsi_ids = 'window.GATSBY_GOOGLE_CLIENT_ID = "'
    .concat(process.env.GATSBY_GOOGLE_CLIENT_ID)
    .concat('";window.GATSBY_REDIRECTION_URL = "')
    .concat(process.env.GATSBY_REDIRECTION_URL)
    .concat('";');

  return (
    <Layout type="homepage">
      <Helmet>
        <link rel="canonical" href={canonical} />
        <script>{gsi_ids}</script>
      </Helmet>
      <GlobalStyle />
      <SEO
        seo={seo}
        lang={language}
        locales={locales}
        translated={translated}
      />
      {ParseHTML(getContent(), language)}
    </Layout>
  );
};

export const query = graphql`
  query HomepageQuery($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    page: allWpPage(filter: {databaseId: {eq: 71566}}) {
      edges {
        node {
          id
          databaseId
          title
          status
          slug
          date
          locale {
            id
          }
          translated {
            id
            link
            content
            seo {
              metaRobotsNofollow
              metaRobotsNoindex
              breadcrumbs {
                text
                url
              }
              canonical
              cornerstone
              focuskw
              metaDesc
              metaKeywords
              opengraphAuthor
              opengraphDescription
              opengraphModifiedTime
              opengraphPublishedTime
              opengraphPublisher
              opengraphTitle
              opengraphSiteName
              opengraphType
              opengraphUrl
              title
              twitterDescription
              twitterTitle
              schema {
                articleType
                pageType
                raw
              }
            }
            locale {
              locale
              id
            }
          }
          seo {
            metaRobotsNofollow
            metaRobotsNoindex
            breadcrumbs {
              text
              url
            }
            canonical
            cornerstone
            focuskw
            metaDesc
            metaKeywords
            opengraphAuthor
            opengraphDescription
            opengraphModifiedTime
            opengraphPublishedTime
            opengraphPublisher
            opengraphTitle
            opengraphSiteName
            opengraphType
            opengraphUrl
            title
            twitterDescription
            twitterTitle
            schema {
              articleType
              pageType
              raw
            }
          }
          content
        }
      }
    }
  }
`;

Homepage.propTypes = {
  data: PropTypes.shape({
    locales: PropTypes.object,
    page: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: {
            content: PropTypes.string,
            seo: PropTypes.shape({}),
            locale: PropTypes.shape({}),
            translated: PropTypes.array,
          },
        })
      ),
    }),
  }),
  pageContext: PropTypes.shape({
    databaseId: PropTypes.string,
    uri: PropTypes.string,
    languageMapping: PropTypes.array,
    content: PropTypes.string,
    slug: PropTypes.string,
    seo: PropTypes.shape,
    locale: PropTypes.shape,
    language: PropTypes.string,
    translated: PropTypes.shape,
  }),
};

export default Homepage;
